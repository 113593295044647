import styles from "@saas-templates/MainLayout/MainLayout.module.scss";
import MainLayout from "@saas-templates/MainLayout/MainLayout";
import HeadBaseLayout from "@saas-organisms/Layout/Head/Base/HeadBaseLayout";
import SignIn from "@saas-pages/Authentication/SignIn/SignIn";
import SignInHeader from "@saas-organisms/Header/SignIn/SignInHeader";
const SignInIndex = () => <MainLayout head={<HeadBaseLayout path='/sign-in' />} components={{
  header: <SignInHeader login={false} />
}} className={{
  article: styles['left-side-none']
}}>
    <SignIn />
  </MainLayout>;
export default SignInIndex;