import { useTranslation } from "next-i18next"
import styles from './SignIn.module.scss'
import Link from "next/link"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import { signIn } from "next-auth/react"
import { DataValueFormPropsI } from "@saas-organisms/Form/Dynamic/interfaces"
import LoginSidebar from "@saas-organisms/Sidebar/Login/LoginSidebar"
import DynamicForm from "@saas-organisms/Form/Dynamic/DynamicForm"
import { initValues, schemaSignIn, validationSchemaSignIn } from "@saas-validations/authentication/signin"
import { CheckTertiary, NotCheckedTertiary } from "@lib-ui/themes"

const SignIn = () => {
  const { t, i18n } = useTranslation("translation")

  const handleSubmit = async (values: DataValueFormPropsI) => {
    await signIn("credentials", { email: values.email, password: values.password })
  }

  return (
    <div className={`flex justify-center items-stretch ${styles['container']}`}>
      <div className="md:basis-1/2 hidden md:block">
        <LoginSidebar />
      </div>
      <div className="basis-full md:basis-1/2">
        <div className={`flex !justify-center items-center ${styles['content-grid']}`}>
          <div className={`basis-full sm:basis-2/3 md:basis-7/12 ${styles['content']}`}>
            <h1 className={styles['title']}>{t('signin.title')}</h1>
            <DynamicForm {...{
              validationSchema: validationSchemaSignIn,
              initValue: initValues,
              schema: schemaSignIn,
              className: styles['form'],
              buttonClassName: styles['button'],
              button: t('signin.button'),
              handleSubmit
            }}>
              <div className={`flex flex-row justify-between items-center ${styles['sub-content']}`}>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        aria-label="checkbox"
                        name="remind-checkbox"
                        icon={<NotCheckedTertiary width={30} height={30} />}
                        checkedIcon={<CheckTertiary width={30} height={30} />}
                      />
                    }
                    label={`${t('signin.check')}`}
                  />
                </div>
                <div>
                  <Link href="/forgotPassword" locale={i18n.language}>
                    {t('signin.forgotPassword')}
                  </Link>
                </div>
              </div>
            </DynamicForm>

            <div className={styles['linkAccount']}>
              <span>{t('signin.account')}&nbsp;</span>
              <Link href="/sign-up" locale={i18n.language}>
                {t('signin.link')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignIn
