import * as yup from "yup"
import { DynamicSchemaTypePropsI } from "@saas-organisms/Form/Dynamic/interfaces"

export const initValues = {

  // email: 'pikatchette@gmail.com',
  // password: 'ghjghjghfhff%U0'

  // email: 'mickael.tonnelier+ttt@lab-sense.io',
  // password: 'pkU,xXPH547u,9R|.8d!'

  email: '',
  password: ''
}

export const schemaSignIn: Array<DynamicSchemaTypePropsI> = [
  {
    name: 'email',
    label: 'dynamicform.email',
    required: true,
    type: 'email',
    props: {
      autoComplete: 'email'
    },
    col: 6,
  },
  {
    name: 'password',
    label: 'dynamicform.password',
    required: true,
    type: 'password',
    props: {
      autoComplete: 'current-password'
    },
    col: 6,
  }
]

export const validationSchemaSignIn = yup.object().shape({
  email: yup
    .string()
    .email('validation.email')
    .required("validation.required"),
  password: yup.string().required("validation.required")
})

